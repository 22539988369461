import React from 'react';
import PropTypes from 'prop-types';
import { showDeleteConfirm } from '../../delete-modal/DeleteModal';

const FooterFixed = ({ count = 0, deleteFunction }) => {
  return (
    <div className="fixedFooter">
      <p>Ըտնրված է ։ {count.length}</p>
      <button type="button" onClick={() => showDeleteConfirm(() => deleteFunction(count))}>
        Ջնջել
      </button>
    </div>
  );
};
FooterFixed.propTypes = {
  count: PropTypes.array,
  deleteFunction: PropTypes.func,
};
export default FooterFixed;
