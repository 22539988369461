import { Button } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import EditIconSvg from '../../svgs/EditIconSvg';
import TrashIconSvg from '../../svgs/TrashIconSvg';
import { showDeleteConfirm } from '../../delete-modal/DeleteModal';

const ActionButtons = ({ editClick, deleteClick }) => {
  return (
    <div className="tableActions">
      <Button onClick={editClick}>
        <EditIconSvg />
      </Button>
      <Button onClick={() => showDeleteConfirm(deleteClick)}>
        <TrashIconSvg />
      </Button>
    </div>
  );
};

ActionButtons.propTypes = {
  editClick: PropTypes.func,
  deleteClick: PropTypes.func,
};
export default ActionButtons;
