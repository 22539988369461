import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { orderTableKeys } from '../../helpers/helpers';
import { useServices } from '../../services/useServices';

export const useOrders = () => {
  const [orders, setOrders] = useState({ count: 0, ordersData: [] });

  const { getTableData, getSingleData, download } = useServices();
  const limit = 10;
  const getData = async (offset, limit) => {
    const { data, count } = await getTableData('/order-product', offset, limit);
    const dataOrder = orderTableKeys(data);
    setOrders({ count, ordersData: dataOrder });
  };
  const changeStatus = async (id) => {
    await getSingleData('/order-product', id).then(() => {
      getData(0, 10);
    });
  };
  useEffect(() => {
    getData(0, limit);
  }, []);
  const OrderColumns = [
    {
      title: 'Պատվիրատու',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Հեռախոս',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Էլ․ հասցե',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Բյուջե',
      dataIndex: 'budget',
      key: 'budget',
    },
    {
      title: 'Տեսակ',
      dataIndex: 'achieve',
      key: 'achieve',
    },
    {
      title: 'Նկարագրություն',
      dataIndex: 'about_project',
      key: 'about_project',
    },
    {
      title: 'Ֆայլեր',
      dataIndex: 'files',
      key: 'files',
      width: 260,

      render: (files, record) =>
        files.map((file, index) => (
          <Button
            key={file}
            className="cvText"
            onClick={() =>
              download(file, record.key, `Order-${record.title}`, '/order-product', getData)
            }
          >
            Ֆայլ &#x2116; {index + 1}
          </Button>
        )),
    },
    {
      title: 'Կարգավիճակ',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (!text ? <span className="newCv">Նոր</span> : 'Դիտված'),
    },
  ];
  return { orders, getData, OrderColumns, changeStatus };
};
