import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken && pathname === '/login') {
      navigate('/vacancies ');
    } else if (!accessToken) {
      navigate('/login');
    }
  }, [pathname]);

  return children;
};

export default AuthProvider;
