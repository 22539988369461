export const vacancyTableKeys = (arr) => {
  return arr.map(({ title_en, id, content_en, status }) => ({
    key: id,
    title: title_en,
    content: content_en,
    status,
    actions: 'actions',
  }));
};

export const blogTableKeys = (arr) => {
  return arr.map(({ title_en, id, content_en, image }) => ({
    key: id,
    image,
    title: title_en,
    content: content_en,
    actions: 'actions',
  }));
};

export const cvTableKeys = (arr) => {
  return arr.map(({ id, cv, checked, vacancies: { title_en } }) => ({
    key: id,
    title: title_en,
    cv,
    checked,
    actions: 'actions',
  }));
};

export const orderTableKeys = (arr) => {
  return arr.map(({ id, name, phone, email, achieve, budget, about_project, files, status }) => ({
    key: id,
    title: name,
    phone,
    email,
    achieve,
    budget,
    about_project,
    files,
    status,
  }));
};
