import React from 'react';

const ClosedEyeSvg = () => {
  return (
    <svg width="22" height="16" xmlns="http://www.w3.org/2000/svg" fill="none">
      <g>
        <path
          stroke="null"
          d="m11,0.20149c-5,0 -9.27,3.17189 -11,7.64925c1.73,4.47736 6,7.64925 11,7.64925c5,0 9.27,-3.17189 11,-7.64925c-1.73,-4.47736 -6,-7.64925 -11,-7.64925zm0,12.74876c-2.76,0 -5,-2.28458 -5,-5.0995c0,-2.81493 2.24,-5.0995 5,-5.0995c2.76,0 5,2.28458 5,5.0995c0,2.81493 -2.24,5.0995 -5,5.0995zm0,-8.1592c-1.66,0 -3,1.36667 -3,3.0597c0,1.69303 1.34,3.0597 3,3.0597c1.66,0 3,-1.36667 3,-3.0597c0,-1.69303 -1.34,-3.0597 -3,-3.0597z"
          fill="#C4CDDE"
          id="svg_1"
        />
        <path
          strokeWidth="3"
          id="svg_3"
          d="m22.75844,-0.35419l-23.38113,16.34638"
          opacity="undefined"
          strokeLinecap="undefined"
          strokeLinejoin="undefined"
          stroke="#c4cdde"
          fill="none"
        />
        <path
          stroke="#ffffff"
          id="svg_6"
          d="m22.64652,0.88343l-21.94029,15.37314"
          opacity="undefined"
          strokeLinecap="undefined"
          strokeLinejoin="undefined"
          fillOpacity="0"
          fill="none"
        />
      </g>
    </svg>
  );
};

export default ClosedEyeSvg;
