import { Modal } from 'antd';

const { confirm } = Modal;

export const showDeleteConfirm = (onDelete) => {
  confirm({
    className: 'deleteModal',
    title: 'Համոզվա՞ծ եք որ ցանկանում եք ջնջել',
    okText: 'Այո',
    okType: 'danger',
    cancelText: 'Ոչ',
    onOk() {
      onDelete();
    },
  });
};
