import { notification } from 'antd';
import { useEffect, useRef } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useServices } from '../../services/useServices';

export const useVacancy = () => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = () => {
    api.info({
      message: 'Զգուշացում',
      description: 'Դաշտերի պարունակությունը սխալ է',
    });
  };
  const formikRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const { createRequest, editRequest, getSingleData } = useServices();
  const title = id ? 'Փոփոխել մասնագիտությունը' : 'Ավելացնել մասնասգիտություն';

  const getEditableVacancy = async () => {
    const data = await getSingleData('/vacancy', id);
    if (formikRef?.current && data) {
      Object.keys(data).forEach((item) => {
        if (data[item] === null) {
          delete data[item];
        }
        if (item === 'status') {
          data.status = Boolean(data.status);
        }
      });
      formikRef.current.setValues(data);
    }
  };
  useEffect(() => {
    if (id) {
      getEditableVacancy();
    }
  }, [id]);

  const checkEmptyVacancy = (values) => {
    let checked = true;
    values.status = Number(values.status);
    Object.keys(values).forEach((prop) => {
      if (typeof values[prop] === 'string') {
        const value = values[prop].replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '').trim();

        if (!value) {
          if (prop === 'content_en') {
            checked = false;
          }
        }
      }
    });
    return checked;
  };
  const actionVacancies = async (values) => {
    const modifledValues = {
      ...values,
    };
    const check = checkEmptyVacancy(modifledValues);
    if (check) {
      if (id) {
        await editRequest('/vacancy', id, modifledValues);
      } else {
        await createRequest('/vacancy', modifledValues);
      }
      navigate('/vacancies');
    } else {
      openNotification();
    }
  };
  return {
    formikRef,
    actionVacancies,
    title,
    openNotification,
    contextHolder,
  };
};
