import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'antd';
import { routes } from '../../routes';
import { useServices } from '../../services/useServices';
import styles from './Sidebar.module.scss';

const SideBar = () => {
  const { logOut } = useServices();

  return (
    <div className={styles.sidebar}>
      {routes.map(
        ({ route, name }) =>
          name && (
            <div key={name}>
              <NavLink
                to={`${route}`}
                className={({ isActive }) => (isActive ? styles.selectedLink : '')}
              >
                {name}
              </NavLink>
            </div>
          )
      )}
      <Button type="danger" onClick={logOut}>
        Դուրս գալ
      </Button>
    </div>
  );
};

export default SideBar;
