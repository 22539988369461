import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Alert, Space } from 'antd';
import styles from './Login.module.scss';
import { useLogin } from './useLogin';
import EyeSvg from '../../components/svgs/EyeSvg';
import ClosedEyeSvg from '../../components/svgs/ClosedEyeSvg';
import { LoginValidationSchema } from '../../validations-schema/ValidationsSchema';
import { initialValuesLogin } from '../../constants/initialValues';

const Login = () => {
  const { showPassword, passwordShow, handeLogin, isError } = useLogin();
  const inpuType = showPassword ? 'password' : 'text';
  const eyeState = showPassword ? <EyeSvg /> : <ClosedEyeSvg />;
  return (
    <div className={styles.login}>
      {isError.show && (
        <div className={styles.errorMessage}>
          <Space direction="vertical">
            <Alert banner message={isError.message} type="error" showIcon />
          </Space>
        </div>
      )}
      <div className={styles.loginWrapper}>
        <h1>Login Aimit</h1>
        <Formik
          initialValues={initialValuesLogin}
          onSubmit={(values) => handeLogin(values)}
          validationSchema={LoginValidationSchema}
        >
          {({ errors, touched }) => (
            <Form>
              <div className={`${errors.email && touched.email ? styles.formInvalid : ''}`}>
                <label>Էլ հասցե</label>
                <div className={styles.formItem}>
                  <Field type="text" name="email" />
                </div>
                {errors.email && touched.email && <span>{errors.email}</span>}
              </div>
              <div className={`${errors.password && touched.password ? styles.formInvalid : ''}`}>
                <label>Գաղտնաբառ</label>
                <div className={styles.formItem}>
                  <Field type={inpuType} name="password" />
                  <button type="button" onClick={passwordShow}>
                    {eyeState}
                  </button>
                </div>
                {errors.password && touched.password && <span>{errors.password}</span>}
              </div>

              <button type="submit">Մուտք</button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
