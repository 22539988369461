import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import TrashIconSvg from '../../components/svgs/TrashIconSvg';
import { useServices } from '../../services/useServices';
import { cvTableKeys } from '../../helpers/helpers';
import { showDeleteConfirm } from '../../components/delete-modal/DeleteModal';

export const useCv = () => {
  const { getTableData, deleteRequest, download } = useServices();
  const [cv, setCv] = useState({ count: 0, cvData: [] });
  const [selectedIds, setSelectedIds] = useState([]);

  const deleteCv = (ids) => {
    deleteRequest('delete-cv', ids).then(() => getCvData(0, 10));
    setSelectedIds([]);
  };
  const getCvData = async (from, limit) => {
    const offset = from * limit;
    const res = await getTableData('cv', offset, limit);
    const cvData = cvTableKeys(res.data);
    setCv({
      count: res.count,
      cvData,
    });
  };

  const CvColumns = [
    {
      title: 'Աշխատանք',
      dataIndex: 'title',
      key: 'title',
      width: 500,
    },

    {
      title: 'Ինքնակենսագրական',
      dataIndex: 'cv',
      key: 'cv',
      render: (text, record) => (
        <Button
          className="cvText"
          onClick={() => download(text, record.key, `CV - ${record.title}`, '/cv', getCvData)}
        >
          {text}
        </Button>
      ),
    },
    {
      title: 'Կարգավիճակ',
      dataIndex: 'checked',
      key: 'checked',
      render: (text) => (!text ? <span className="newCv">Նոր</span> : 'Դիտված'),
    },
    {
      title: 'Գործ․',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,

      render: (_, record) => (
        <div className="tableActions">
          <Button onClick={() => showDeleteConfirm(() => deleteCv([record.key]))}>
            <TrashIconSvg />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getCvData(0, 10);
  }, []);
  return { CvColumns, cv, selectedIds, setSelectedIds, getCvData, deleteCv, showDeleteConfirm };
};
