export const initialValuesLogin = {
  email: '',
  password: '',
};

export const initialValuesBlog = {
  image: '',
  title_en: '',
  content_en: '',
  title_de: '',
  content_de: '',
  category_en: '',
  category_de: '',
  author_name: '',
  author_image: '',
};

export const initialValuesVacancy = {
  title_en: '',
  content_en: '',
  title_de: '',
  content_de: '',
  status: false,
};
