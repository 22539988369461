import React from 'react';
import { Tabs, Button, Row, Col } from 'antd';
import { Field, Form, Formik } from 'formik';
import { useVacancy } from './useVacancy';
import { EditorValidationVacancy } from '../../validations-schema/ValidationsSchema';
import styles from './Vacancy.module.scss';
import { initialItemsVacancy } from '../../constants/tabItems';
import { initialValuesVacancy } from '../../constants/initialValues';

const Vacancy = () => {
  const { formikRef, actionVacancies, title, contextHolder } = useVacancy();

  return (
    <div className={styles.vacancy}>
      {contextHolder}
      <h1 role="presentation">{title}</h1>
      <div className={styles.itemsWrapper}>
        <Formik
          initialValues={initialValuesVacancy}
          onSubmit={actionVacancies}
          validationSchema={EditorValidationVacancy}
          innerRef={formikRef}
        >
          {() => {
            return (
              <Form>
                <Row>
                  <Tabs defaultActiveKey="1" items={initialItemsVacancy} />

                  <div className={styles.outerTabsContent}>
                    <Row>
                      <Col span={1}>
                        <label className="label">Ընտրել կարգավիճակը</label>
                        <Field type="checkbox" name="status" />
                      </Col>
                    </Row>
                    <Button type="primary" htmlType="submit" className="submit-btn">
                      Պահպանել
                    </Button>
                  </div>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Vacancy;
