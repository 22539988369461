import React from 'react';
import { Table } from 'antd';
import { useCv } from './useCv';
import styles from './cv.module.scss';
import FotterFixed from '../../components/shared/footer-fixed/FooterFixed';

const CV = () => {
  const {
    CvColumns,
    cv: { cvData, count },
    setSelectedIds,
    getCvData,
    selectedIds,
    deleteCv,
  } = useCv();
  return (
    <div className={styles.cv}>
      <div className={styles.pageHeader}>
        <h1>Ինքնակենսագրականներ</h1>
      </div>

      <div className="defaultTable">
        <Table
          dataSource={cvData}
          columns={CvColumns}
          selectedRowKeys={selectedIds}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selectedIds,
            onChange: (ids) => setSelectedIds(ids),
          }}
          preserveSelectedRowKeys={false}
          pagination={
            count > 10
              ? {
                  total: count,
                  showSizeChanger: false,
                  onChange: (page, pageSize) => getCvData(page - 1, pageSize),
                }
              : false
          }
        />
      </div>
      {selectedIds.length > 0 ? (
        <FotterFixed count={selectedIds} deleteFunction={deleteCv} />
      ) : null}
    </div>
  );
};

export default CV;
