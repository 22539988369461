import React from 'react';
import PropTypes from 'prop-types';
import SideBar from '../sidebar/SideBar';
import styles from './AuthComponent.module.scss';

const AuthComponent = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <SideBar />
      {children}
    </div>
  );
};

export default AuthComponent;

AuthComponent.propTypes = {
  children: PropTypes.element,
};
