import { Table } from 'antd';
import React from 'react';
import styles from './Orders.module.scss';
import { useOrders } from './useOrders';

const Orders = () => {
  const {
    getData,
    OrderColumns,
    changeStatus,
    orders: { count, ordersData },
  } = useOrders();
  return (
    <div className={styles.blogs}>
      <div className={styles.pageHeader}>
        <h1>Պատվերներ</h1>
      </div>
      <div>
        <div className="defaultTable ordersTable">
          <Table
            dataSource={ordersData}
            columns={OrderColumns}
            pagination={
              count > 10
                ? {
                    total: count,
                    showSizeChanger: false,
                    onChange: (page, pageSize) => getData(page - 1, pageSize),
                  }
                : false
            }
            onRow={(record) => {
              return {
                onClick: () => changeStatus(record.key),
              };
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Orders;
