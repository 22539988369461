import React from 'react';
import CustomEditor from '../components/shared/custom-editor/CustomEditor';

export const initialItemsVacancy = [
  {
    label: 'Անգլերեն',
    children: <CustomEditor initialNames={{ title: 'title_en', content: 'content_en' }} />,
    key: '1',
  },
  {
    label: 'Գերմաներեն',
    children: (
      <CustomEditor tabGerman initialNames={{ title: 'title_de', content: 'content_de' }} />
    ),
    key: '2',
  },
];

export const initialItemsBlog = [
  {
    label: 'Անգլերեն',
    children: (
      <CustomEditor
        initialNames={{
          title: 'title_en',
          content: 'content_en',
          category: 'category_en',
        }}
        blog
      />
    ),
    key: '1',
  },
  {
    label: 'Գերմաներեն',
    children: (
      <CustomEditor
        tabGerman
        initialNames={{
          title: 'title_de',
          content: 'content_de',
          category: 'category_de',
        }}
        blog
      />
    ),
    key: '2',
  },
];
