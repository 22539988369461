import * as Yup from 'yup';

export const LoginValidationSchema = Yup.object().shape({
  email: Yup.string().email('էլ․ հասցեի սխալ ֆորմատ').required('Պարտադիր դաշտ'),
  password: Yup.string()
    .min(8, 'Գաղտնաբառը պետք է պարունակի առնվազն 8 նիշ')
    .max(255, 'Գաղտնաբառը պետք է չլինի ավել քան 255 նիշ')
    .matches(/[0-9]/, 'Գաղտնաբառը պետք է պարունակի թիվ')
    .matches(/[a-z]/, 'Գաղտնաբառը պետք է պարունակի փոքրատառ')
    .matches(/[A-Z]/, 'Գաղտնաբառը պետք է պարունակի մեծատառ')
    .matches(/[^\w]/, 'Գաղտնաբառը պետք է պարունակի սիմվոլ')
    .required('Պարտադիր դաշտ'),
});

export const EditorValidationVacancy = Yup.object().shape({
  title_en: Yup.string().trim().required('Պարտադիր դաշտ'),
  content_en: Yup.string()
    .matches(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, 'aaaaa')
    .required('Պարտադիր դաշտ'),
});
export const EditorValidationBlog = Yup.object().shape({
  title_en: Yup.string().trim().required('Պարտադիր դաշտ'),
  content_en: Yup.string().trim().required('Պարտադիր դաշտ'),
  category_en: Yup.string().required('Պարտադիր դաշտ'),
  image: Yup.string().required('Պարտադիր դաշտ'),
  share_image: Yup.string().required('Պարտադիր դաշտ'),
  author_name: Yup.string().required('Պարտադիր դաշտ'),
});
