import React from 'react';

const TrashIconSvg = () => {
  return (
    <svg
      id="delete_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="delete_black_24dp-2" data-name="delete_black_24dp" opacity="0.5">
        <path id="Path_6804" data-name="Path 6804" d="M0,0H24V24H0Z" fill="none" />
        <path
          id="Path_6805"
          data-name="Path 6805"
          d="M16,9V19H8V9h8M14.5,3h-5l-1,1H5V6H19V4H15.5ZM18,7H6V19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2Z"
          fill="#43425d"
        />
      </g>
    </svg>
  );
};

export default TrashIconSvg;
