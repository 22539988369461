import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { vacancyTableKeys } from '../../helpers/helpers';
import { useServices } from '../../services/useServices';
import FireSvg from '../../components/svgs/FireSvg';
import ActionButtons from '../../components/shared/action-buttons/ActionButtons';

export const useVacancies = () => {
  const navigate = useNavigate();
  const [vacancies, setVacancies] = useState({ data: [], count: 0 });
  const [selectedIds, setSelectedIds] = useState([]);
  const { getTableData, deleteRequest } = useServices();
  const limit = 10;

  const getData = async (from, limit) => {
    const offset = from * limit;
    const { data, count } = await getTableData('/vacancy', offset, limit);
    const vacancyData = vacancyTableKeys(data);
    setVacancies({ count, data: vacancyData });
  };
  const addVacancy = () => {
    navigate('/create-vacancy');
  };
  const deleteVacancy = async (ids) => {
    await deleteRequest('delete-vacancy', ids).then(() => {
      getData(0, limit);
      setSelectedIds([]);
    });
  };

  const vacancyColumns = [
    {
      title: 'Վերնագիր',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Տեքստ',
      dataIndex: 'content',
      key: 'content',
      width: 500,
      render: (text) => <div className="tableText" dangerouslySetInnerHTML={{ __html: text }} />,
    },
    {
      title: 'Կարգավիճակ',
      dataIndex: 'status',
      key: 'status',
      render: (status) =>
        status ? (
          <div className="vacancyStatus">
            <FireSvg />
          </div>
        ) : (
          <div className="status_no" />
        ),
    },
    {
      title: 'Գործ․',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (_, record) => (
        <ActionButtons
          editClick={() => navigate(`/edit-vacancy/${record.key}`)}
          deleteClick={() => deleteVacancy([record.key])}
        />
      ),
    },
  ];
  useEffect(() => {
    getData(0, limit);
  }, []);

  return {
    vacancies,
    getData,
    addVacancy,
    deleteVacancy,
    setSelectedIds,
    selectedIds,
    vacancyColumns,
  };
};
