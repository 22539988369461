import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const useServices = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  axios.defaults.baseURL = `${BASE_URL}/api`;

  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  const login = async (values) => {
    try {
      const {
        data: { accessToken },
      } = await axios.post('/login', values, {});
      localStorage.setItem('accessToken', accessToken);
      navigate('/vacancies');
    } catch (error) {
      errorHandler(error);
    }
  };
  const logOut = () => {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
    navigate('/login');
  };
  const errorHandler = (error) => {
    if (error.response?.status === 401) {
      logOut();
    } else {
      throw error;
    }
  };
  const getTableData = async (url, offset, limit) => {
    try {
      const { data } = await axios.get(url, {
        params: {
          offset,
          limit,
        },
      });
      return data;
    } catch (error) {
      errorHandler(error);
    }
  };

  const getSingleData = async (url, id) => {
    try {
      const {
        data: { data },
      } = await axios.get(`${url}/${id}`);
      return data;
    } catch (error) {
      errorHandler(error);
    }
  };
  const createRequest = async (url, values) => {
    try {
      const {
        data: { data = {} },
      } = await axios.post(url, values);
      return data;
    } catch (error) {
      errorHandler(error);
    }
  };

  const editRequest = async (url, id, values) => {
    try {
      const {
        data: { data = {} },
      } = await axios.put(`${url}/${id}`, values);
      return data;
    } catch (error) {
      errorHandler(error);
    }
  };

  const deleteRequest = async (url, ids) => {
    try {
      const {
        data: { data = {} },
      } = await axios.delete(url, { data: { ids } });
      return data;
    } catch (error) {
      errorHandler(error);
    }
  };

  const uploadImageRequest = async (image) => {
    try {
      const formData = new FormData();
      formData.append('image', image);
      const {
        data: { data },
      } = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return data;
    } catch (error) {
      errorHandler(error);
    }
  };
  const download = async (url, id, fileName, singleDataUrl, getData) => {
    fetch(`${BASE_URL}/${url}`)
      .then((res) => res.blob())

      .then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = fileName;
        alink.click();
      });
    await getSingleData(singleDataUrl, id).then(() => {
      getData(0, 10);
    });
  };

  return {
    login,
    logOut,
    getTableData,
    getSingleData,
    createRequest,
    editRequest,
    deleteRequest,
    uploadImageRequest,
    download,
  };
};
