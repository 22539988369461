import React from 'react';
import { Button, Table } from 'antd';
import { useVacancies } from './useVacancies';
import styles from './Vacanices.module.scss';
import FooterFixed from '../../components/shared/footer-fixed/FooterFixed';

const Vacancies = () => {
  const {
    vacancies: { data, count },
    getData,
    addVacancy,
    setSelectedIds,
    selectedIds,
    deleteVacancy,
    vacancyColumns,
  } = useVacancies();
  return (
    <div className={styles.vacancies}>
      <div className={styles.pageHeader}>
        <h1>Մասնագիտություններ</h1>
        <Button onClick={addVacancy} type="primary">
          Ավելացնել մասնագիտություն
        </Button>
      </div>
      <div>
        <div className="defaultTable">
          <Table
            dataSource={data}
            columns={vacancyColumns}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectedIds,
              onChange: (ids) => setSelectedIds(ids),
            }}
            pagination={
              count > 10
                ? {
                    total: count,
                    showSizeChanger: false,
                    onShowSizeChange: (_, pageSize) => getData(0, pageSize),
                    onChange: (page, pageSize) => getData(page - 1, pageSize),
                  }
                : false
            }
          />
        </div>
        {selectedIds.length > 0 ? (
          <FooterFixed count={selectedIds} deleteFunction={deleteVacancy} />
        ) : null}
      </div>
    </div>
  );
};

export default Vacancies;
